<template>
  <div class="pwdcontent Pbm100">
    <el-container class="wrap">
      <el-header>
        <div class="title F16 orange bg_fff Plf20 Ptp15 Pbm15">
          <slot name="menu"> </slot>
        </div>
      </el-header>
      <el-main class="Mtp20">
        <div class="container bg_fff">
          <div class="content">
            <slot name="pwdContair"> </slot>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      pwdChangeForm: {
        phone: "",
        verify: "",
        pwd: "",
        pwdComfire: "",
      },
      pwdChangeFormRule: {
        phone: [
          { required: true, message: "请输入绑定手机号码", trigger: "change" },
        ],
        verify: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
        pwd: [{ required: true, message: "请输入新密码", trigger: "change" }],
        pwdComfire: [
          { required: true, message: "请再次确认新密码", trigger: "blur" },
        ],
      },
    };
  },
};
</script>
<style scoped lang="scss">
.pwdcontent {
  margin-top: 25px;
  .el-header {
    height: 25px !important;
    padding: 0px !important;
  }
  .el-main {
    padding: 20px 0px !important;
    .container {
      padding: 20px;
    }
  }
}
</style>