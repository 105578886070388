<!--
 * @Author: Jerry
 * @Date: 2021-01-27 09:33:10
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-22 00:48:12
 * @Description: file content
-->
<template>
  <div>
    <pwd-contair>
      <div slot="menu">历史开票信息</div>
      <div slot="pwdContair">
        <!-- <tag-components>
          <div slot="search">
            <div class="ssssSborder">
              <div class="ssssSearch">
                <el-input
                  class="searchinput"
                  placeholder="开票时间、开票金额"
                ></el-input>
              </div>
              <div class="searchicon bg_f4f4">
                <i class="el-icon-search co_000"></i>
              </div>
            </div>
          </div>
        </tag-components> -->
        <div class="invoiceFrom">
          <el-button type="primary" size="small" @click="applyInvoice">申请发票</el-button>
          <el-table
            :header-cell-style="{ background: '#f4f4f4' }"
            :data="dataSource"
            border
            style="width: 100%"
            class="Mtp20"
          >
            <el-table-column
              align="center"
              prop="invoiceTime"
              label="申请时间"
              width="200"
            >
              <template slot-scope="scope">
                {{ formatDate(scope.row.invoiceTime * 1000) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="money" label="金额">
            </el-table-column>
            <el-table-column align="center" prop="invoiceCode" label="发票代码">
            </el-table-column>
            <el-table-column align="center" prop="invoiceNo" label="发票号码">
            </el-table-column>
            <el-table-column align="center" prop="invoice" label="发票文件">
              <template slot-scope="scope">
                <div v-if="scope.row.invoice != ''">
                  <el-button
                          type="text"
                          size="mini"
                          @click="filePreview(scope.row.invoice)"
                          v-if="judgeFileType(scope.row.invoice) == 'pdf' && scope.row.invoice"
                  >查看凭证</el-button
                  >
                  <el-image
                    :src="scope.row.invoice"
                    :preview-src-list="[scope.row.invoice]"
                    v-if="judgeFileType(scope.row.invoice) == 'image' && scope.row.invoice"
                    style="width: 100px"
                  ></el-image>
                  <el-link v-if="judgeFileType(scope.row.invoice) == 'image' && scope.row.invoice" target="_blank" :href="scope.row.invoice">点击下载</el-link>
                </div>
                <div v-else class="F12">
                  发票文件暂未上传
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="auditStatus" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.auditStatus == 'D'">审核中</span>
                <span
                  v-else-if="scope.row.auditStatus == 'Y'"
                  style="color: green"
                  >审核通过</span
                >
                <span
                  v-else-if="scope.row.auditStatus == 'N'"
                  style="color: red; cursor: pointer;"
                  @click="() => onCLickStatus(scope.row.auditReason)"
                >
                  审核不通过<br/>查看原因
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="action" label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="getOrderByInvoice(scope.row.id)"
                  >订单详情</el-button>
                <el-button
                  style="margin-top: 5px"
                  v-if="scope.row.auditStatus == 'N'"
                  type="primary"
                  plain
                  size="mini"
                  @click="againSubmitAuditData(scope.row.id)"
                  >重新提交审核数据</el-button>
                <el-button
                    style="margin-top: 5px"
                    v-if="scope.row.auditStatus == 'N'"
                    type="primary"
                    plain
                    size="mini"
                    @click="delAuditData(scope.row.id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="grid Mtp40 Mbm40 floatR">
          <el-pagination
            background
            :total="ipagination.totalCount"
            :page-size="ipagination.size"
            :current-page="ipagination.current"
            @size-change="onSizeChange"
            @current-change="onPageChange"
            layout="total, sizes, prev, pager, next"
          ></el-pagination>
        </div>
        <div class="clearfix"></div>
      </div>
    </pwd-contair>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="500px">
      <div>
        {{dialogMessage}}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 订单详情 -->
    <orderByInvoice ref="orderByInvoice"></orderByInvoice>
    <!-- 待开票订单 -->
    <orderToInvoice ref="orderToInvoice" @success="loadData"></orderToInvoice>
  </div>
</template>
<script>
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import orderToInvoice from "@/components/invoice/orderToInvoice";
import { listMixin } from "@/mixins/listMixins";
// import TagComponents from "@/components/tag/tagComponents.vue";
import orderByInvoice from "./modules/orderByInvoice";
import { getOrderInvoiceDataPage } from "@/api/user";
import { getOrderDetail, againSubmitAuditData, delAuditData } from "@/api/product";
import { formatDate } from "@/util/util";
import { mapGetters } from "vuex";
export default {
  mixins: [listMixin],
  components: { pwdContair, orderByInvoice, orderToInvoice },
  data() {
    return {
      extraParam: {
        userId: "",
      },
      url: {
        list: getOrderInvoiceDataPage,
      },
      dialogMessage: '',
      dialogVisible: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.extraParam.userId = this.userInfo.id;
    this.loadData();
  },
  methods: {
    formatDate,
    // 获取开票订单列表
    getOrderByInvoice(id) {
      getOrderDetail({
        userId: this.userInfo.id,
        id: id,
      }).then((res) => {
        if (res.success) {
          this.$refs.orderByInvoice.showModal(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 重新提交
    againSubmitAuditData(id) {
      let data = {
        dataId: id,
      };
      againSubmitAuditData(data).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          this.loadData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 申请开票
    applyInvoice() {
      this.$refs.orderToInvoice.showModal();
    },
    delAuditData(id) {
      let data = {
        id: id,
      };
      delAuditData(data).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          this.loadData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onCLickStatus(reason){
      this.dialogVisible = true
      this.dialogMessage = reason
    },
    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped lang="scss"></style>
