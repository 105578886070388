/*
 * @Author: Jerry
 * @Date: 2021-01-27 17:45:36
 * @Description: file content
 */
import { httpGet, httpPost } from '@/axios/axios';

// 商品主信息新增编辑
export const saveOrUpdate = (data) => httpPost({
  url: '/shop/goods/sellerGoods/saveOrUpdate',
  data: data
});

// 商品类目信息列表
export const getList = () => httpGet({
  url: '/shop/goods/goodstype/list'
});

// 根据商品ID获取整个商品的信息
export const getGoodsInfoById = (id) => httpGet({
  url: '/shop/goods/sellerGoods/getGoodsInfoById',
  params: {
    id: id
  }
});

// 根据用户ID分页获取用户商品信息
export const getUserGoodsList = (params) => httpGet({
  url: '/shop/goods/sellerGoods/getUserGoodsList',
  params: params
});

// 根据商品类目ID获取类目底下的属性
export const getGoodsTypeProperty = (id) => httpGet({
  url: '/shop/goods/goodstype/getGoodsTypeProperty',
  params: {
    id: id
  }
});

// 根据商品分类ID获取父类及自身的全部销售属性
export const getGoodsParentProperty = (id) => httpGet({
  url: '/shop/goods/sellerGoods/getGoodsParentProperty',
  params: {
    typeId: id
  }
});

// 商品属性销售参数新增编辑
export const saveOrUpdateSell = (data) => httpPost({
  url: '/shop/goods/sellerGoods/saveOrUpdateSell',
  data: data
});

// 根据商品ID提交商品审核
export const goodsSumbitApply = (id) => httpGet({
  url: '/shop/goods/sellerGoods/goodsSumbitApply',
  params: {
    id: id
  }
});

// 卖家发货
export const sellerShipments = (data) => httpPost({
  url: '/shop/sellOrder/sellerShipments',
  data: data
});

// 卖家修改发货
export const supplementShipments = (data) => httpPost({
  url: '/shop/sellOrder/supplementShipments',
  data: data
});

// 根据订单货运类型查询物流信息
export const findLogisticsMessage = (params) => httpGet({
  url: '/shop/order/orderlogistics/findLogisticsMessage',
  params: params
});

// 根据订单id获取发货信息列表
export const findLogisticsList = (params) => httpGet({
  url: '/shop/order/orderlogistics/findLogisticsList',
  params: params
});

// 确认收货
export const affirmGetOrder = (params) => httpGet({
  url: '/shop/buyerOrder/affirmGetOrder',
  params: params
});

// 采购商补充货运信息或收货时调用接口
export const sellerShipmentsByBuyer = (data) => httpPost({
  url: '/shop/buyerOrder/sellerShipments',
  data: data
});

// 买家付款
export const buyerPaymentOrder = (params) => httpGet({
  url: '/shop/buyerOrder/buyerPaymentOrder',
  params: params
});

// 订单开票申请
export const orderOpenInvoiceApply = (params) => httpGet({
  url: '/shop/buyerOrder/orderOpenInvoiceApply',
  params: params
})

// 根据开票信息获取关联的订单信息
export const getOrderByInvoice = (params) => httpGet({
  url: '/shop/buyerOrder/getOrderByInvoice',
  params: params
})
export const getOrderDetail = (params) => httpGet({
  url: '/shop/order/orderinvoice/getOrderDetail',
  params: params
})

// 订单退款
export const buyerOrderRefund = (params) => httpGet({
  url: '/shop/buyerOrder/buyerOrderRefund',
  params: params
})

// 交易关闭
export const affirmCancelOrder = (params) => httpGet({
  url: '/shop/buyerOrder/affirmCancelOrder',
  params: params
})

// 根据商品类型查询面包屑
export const getGoodsTypeNavigation = (params) => httpGet({
  url: '/shop/shopManage/getGoodsTypeNavigation',
  params: params
})

// 根据商品Id查询面包屑
export const getGoodsTypeNavigationByGoodsId = (params) => httpGet({
  url: '/shop/shopManage/getGoodsTypeNavigationByGoodsId',
  params: params
})

// 根据商品no查询面包屑
export const getGoodsTypeNavigationByGoodsNo = (params) => httpGet({
  url: '/shop/shopManage/getGoodsTypeNavigationByGoodsNo',
  params: params
})

//重新提交审核
export const againSubmitAuditData = (data) => httpPost({
  url: '/shop/audit/auditdata/againSubmitAuditData',
  params: data
})

//订单退款重新提交审核数据
export const refundAgainSubmitAuditData = (data) => httpPost({
  url: '/shop/audit/auditdata/refundAgainSubmitAuditData',
  params: data
})

//删除审核信息
export const delAuditData = (data) => httpGet({
  url: '/shop/buyerOrder/orderDeleteInvoiceApply',
  params: data
})

// 上传文件
export const uploadFile = (data) => httpPost({
  url: '/shop/fileservice/uploadFile',
  data: data
})

// 商品下架
export const sellGoodsOutOfStock = (data) => httpGet({
  url: '/shop/goods/sellerGoods/sellGoodsOutOfStock',
  params: data
})

// 物流信息导出
export const downFile = (data) => httpGet({
  url: '/shop/logisticsExportController/exportLogistics',
  params: data,
  responseType: 'blob'
})

// 采购商导入
export const buyerImport = (data) => httpPost({
  url: '/shop/logisticsImportController/buyerImportLogistics',
  data: data
})

// 供应商导入
export const sellerImport = (data) => httpPost({
  url: '/shop/logisticsImportController/sellerImportLogistics',
  data: data
})



// 运单信息查询接口
export const getTransportInfo = (params) => httpGet({
  url: '/shop/order/orderlogistics/getWebTransportInfo',
  params: params
});


// 物流轨迹信息
export const getWebTrackInfo = (params) => httpGet({
  url: '/shop/order/orderlogistics/getWebTrackInfo',
  params: params
});



// 物流信息货运单
export const updateLogistics = (params) => httpPost({
  url: '/shop/logisticsImportController/updateLogistics',
  data: params
});
