// import _this from 'vue'
import BigNumber from "bignumber.js";
export const listMixin = {
  data() {
    return {
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {},
      // 额外的查询条件
      extraParam: {},
      /* 数据源 */
      dataSource: [],
      /* table加载状态 */
      loading: false,
      /* 分页参数 */
      ipagination: {
        totalPage: 0,
        totalCount: 0,
        // 每页数量
        size: 10,
        // 当前页
        current: 1,
      },
      //	选中的数据
      multiSelection: []
    }
  },

  created() {
    if (!this.disableMixinCreated) {
      console.log(' -- mixin created -- ')
      // this.loadData();
      //初始化字典配置 在自己页面定义
      this.initDictConfig();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    loadData(arg) {
      if (!this.url.list) {
        this.$message.error("请设置url.list属性!")
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg != undefined) {
        this.ipagination.current = arg;
      }
      var params = this.getQueryParams(); //查询条件
      this.loading = true;
      this.url.list(params).then(res => {
        let result = res;
        console.log(res.data)
        if (result.success) {
          this.$set(this, 'dataSource', result.data.records);
          if (arg > 1 && this.dataSource.length === 0) {
            this.loadData(arg - 1)
          }
          this.ipagination.totalCount = result.data.total;
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
        if (res.code === 510) {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
        this.loading = false;
      })
    },
    refreshData() {
      this.loadData(this.ipagination.current)
    },
    initDictConfig() {},
    /**
     * 获取搜索条件内容
     */
    getQueryParams() {
      //获取查询条件
      let sqp = {}
      var param = Object.assign(sqp, this.queryParam, this.extraParam);
      param.current = Number(this.ipagination.current);
      param.size = Number(this.ipagination.size);
      return param;
    },
    // 多选事件
    selectChange(val) {
      this.multiSelection = val;
    },
    // 每页显示多少条变动
    onSizeChange: function (size) {
      this.ipagination.current = 1;
      this.ipagination.size = size;
      this.loadData();
    },
    // 分页变动
    onPageChange: function (page) {
      this.ipagination.current = page;
      this.loadData();
    },
    /**
     * 搜索按钮事件
     */
    searchQuery() {
      this.loadData(1);
    },
    /**
     * 重置
     */
    searchReset() {
      this.queryParam = {}
      this.loadData(1);
    },
    /**
     * 新增按钮
     */
    showAdd() {
      this.$refs.modalForm.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.modalForm.add();
        this.$refs.modalForm.title = "新增";
      })
    },
    /**
     * 删除按钮
     */
    showDelete(records) {
      this
        .$confirm("确定删除吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          this.url.del(records.id).then(res => {
            if (res.data.success) {
              this.loadData(1);
            } else {
              this.$message.warning(res.data.msg)
            }
          }).catch(res => {
            this.$message.warning(res.data.msg)
          })
        })
        .catch(function () {});
    },
    /**
     * 编辑按钮
     * @param {*} record 
     */
    showEdit(record) {
      this.$refs.modalForm.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.modalForm.edit(record);
        this.$refs.modalForm.title = "编辑";
      })
    },
    /**
     * 查看按钮
     * @param {*} record 
     */
    showView(record) {
      this.$refs.modalView.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.modalView.see(record);
        this.$refs.modalView.title = "查看";
      })
    },
    /**
     * 多条删除
     */
    handleMultiDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择至少一条数据'
        })
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var ids = []
        this.multiSelection.forEach(element => {
          ids.push(element.id)
        });
        ids = ids.join(",")
        this.url.del(ids).then((res) => {
          var result = res.data;
          if (result.success) {
            this.loadData();
            this.$message({
              type: "success",
              message: "操作成功！",
            });
          } else {
            this.$message({
              type: "error",
              message: result.msg,
            });
          }
        });
      });
    },
    //获取计算的两位数总金额
    getTotalPrice(price, amount) {
      var totalPrice = new BigNumber(price)
        .multipliedBy(new BigNumber(amount))
        .toFixed(2);
      return totalPrice;
    },
  }
}