<!--
 * @Author: Jerry
 * @Date: 2021-03-21 23:44:24
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-22 00:42:20
 * @Description: 可开票订单
-->
<template>
  <div>
    <el-dialog
      title="选择需要开票的订单"
      :visible.sync="dialogVisible"
      width="1000px"
      v-dialogdrag
      class="avue-dialog"
      top="5vh"
    >
      <div>
        <el-table
          ref="multipleTable"
          :data="dataSource"
          style="width: 100%"
          max-height="700"
          border
          size="mini"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" size="mini">
                <el-form-item label="商品名称：">
                  <span>{{ scope.row.goodsTitle }}</span>
                </el-form-item>
                <el-form-item label="规格：">
                  <span>{{ scope.row.goodsSellName }}</span>
                </el-form-item>
                <el-form-item label="数量：">
                  <span>{{ scope.row.amount }}</span>
                </el-form-item>
                <el-form-item label="订单时间：">
                  <span>{{ $formatDate(scope.row.orderTime * 1000) }}</span>
                </el-form-item>
                <el-form-item label="订单金额：">
                  <span>{{ scope.row.orderMoney }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="orderNo" label="订单号"> </el-table-column>
          <el-table-column prop="orderTime" label="订单时间">
            <template slot-scope="scope">
              {{ $formatDate(scope.row.orderTime * 1000) }}
            </template>
          </el-table-column>
          <el-table-column prop="orderMoney" label="金额"> </el-table-column>
        </el-table>

        <div class="grid Mtp20 Mbm10 floatR">
          <el-pagination
            background
            :total="ipagination.totalCount"
            :page-size="ipagination.size"
            :current-page="ipagination.current"
            @size-change="onSizeChange"
            @current-change="onPageChange"
            layout="total, sizes, prev, pager, next"
          ></el-pagination>
        </div>
        <div class="clearfix"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="orderOpenInvoiceApply" :loading="loading">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getBuyerOpenInvoiceOrderPage } from "@/api/user";
import { orderOpenInvoiceApply } from "@/api/product";
import { listMixin } from "@/mixins/listMixins";
import { mapGetters } from "vuex";
export default {
  mixins: [listMixin],
  name: "",
  data() {
    return {
      dialogVisible: false,
      loading: false,
      multipleSelection: [],
      extraParam: {
        userId: "",
      },
      url: {
        list: getBuyerOpenInvoiceOrderPage,
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.multipleSelection.forEach((ele) => {
        ids.push(ele.orderId);
      });
      return ids.join(",");
    },
  },
  watch: {},
  mounted() {},
  methods: {
    showModal() {
      this.dialogVisible = true;
      this.extraParam.userId = this.userInfo.id;
      this.loadData();
    },
    // 多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 开票申请
    orderOpenInvoiceApply() {
      let orderId = this.ids;
      if (this.validatenull(orderId)) {
        this.$message({
          type: 'waring',
          message: '至少选中一条数据！'
        })
        return false
      }
      this.$confirm("是否将选中的数据提交开票申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          orderOpenInvoiceApply({
            orderId: orderId,
          }).then((res) => {
            this.loading = false;
            if (res.success) {
              this.loadData();
              this.dialogVisible = false;
              this.$message.success(res.msg);
              this.$emit('success')
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>

