<!--
 * @Author: Jerry
 * @Date: 2021-03-02 10:49:41
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-15 01:02:33
 * @Description: 历史开票信息中的订单详情
-->
<template>
  <div>
    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="900px">
      <div>
        <el-table :data="tableData" border style="width: 100%" size="mini" max-height="250">
          <el-table-column prop="orderNo" label="订单号">
          </el-table-column>
          <el-table-column prop="orderTime" label="订单时间">
          </el-table-column>
          <el-table-column prop="orderMoney" label="订单金额">
          </el-table-column>
          <el-table-column prop="goodsTitle" label="商品名称">
          </el-table-column>
          <el-table-column prop="action" label="操作">
            <template slot-scope="scope">
              <div>
                <el-button type="primary" size="mini" @click="checkOrder(scope.row.orderId)">查看订单</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "orderByInvoice",
  data() {
    return {
      tableData: [],
      dialogVisible: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    showModal(data) {
      this.$set(this, 'tableData', data);
      this.dialogVisible = true;
    },
    checkOrder(orderId) {
      this.$router.push({
        name: 'OrderDetail',
        query: {
          orderId
        }
      })
    }
  },
};
</script>

<style scoped>
</style>
